@import "./colors.scss";

:root {
  width: 100%;
  height: 100%;
}

body,
html,
.root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-y: scroll !important;
}

.root {
  justify-content: center;
  align-items: start;
}

.content-inputs {
  max-width: 25em;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-staticmsg {
  max-width: 25em;
  width: 100%;
  display: flex;
  justify-content: start;
  font-family: "Gill Sans", sans-serif;
  font-size: 1em;
}

.app {
  max-width: 75em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 1.25em;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  margin-bottom: 3em;
}

.header-logo {
  width: 24em;
  cursor: pointer;
}

.header-content {
  width: 100%;
}

.header-content-items {
  border-radius: 0.5em;
  display: flex;
  justify-content: end;
  background: $componentbg;
  width: 100%;
  height: 4em;
  gap: 1.2em;
  color: #ffff;
}

.header-content-profile {
  font-size: 1.8em !important;
  padding: 0.5em;
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5em;
}

.content-send {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 25em;
  width: 100%;
  border-radius: 0.4em;
  margin-top: 3.125em;
  background: $actionbtn;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  border-radius: 0.5em;
  color: #ffff;
  font-size: 1.1em;
  font-family: "Gill Sans", sans-serif;
  cursor: pointer;
  margin-bottom: 20px;
}

.content-send.email {
  bottom: auto;
  margin-top: 7em;
}

.content-send.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.error-message {
  max-width: 25em;
  width: 100%;
  display: flex;
  justify-content: start;
  font-size: 1em;
  font-family: "Gill Sans", sans-serif;
  padding: 0.625em 0em 0.625em 0em;
  text-align: start;
}

.content-agreement.datetime {
  justify-content: start;
  gap: 0;

  div {
    outline: none;
    text-decoration: none;
    color: #6566b5;
    font-size: 0.8em;
    font-family: "Gill Sans", sans-serif;
  }
}

.content-agreement {
  max-width: 25em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  max-width: 25em;
  width: 100%;
  a {
    outline: none;
    text-decoration: none;
    color: #6566b5;
    font-size: 0.8em;
    font-family: "Gill Sans", sans-serif;
  }
}

.agreement-content {
  display: flex;
  flex-direction: column;
  font-family: "Gill Sans", sans-serif;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .app {
    margin-top: 10px;
    height: auto;
    overflow-y: scroll;
  }

  body,
  html,
  .root {
    overflow-y: scroll !important;
  }

  .header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3em;
  }

  .header-content {
    width: 90%;
  }

  .content {
    height: 100%;
    justify-content: start;
  }

  .header-content-items {
    justify-content: center;
    gap: 0.5em;
  }

  .error-message {
    font-size: 0.9em;
  }

  .content-send {
    margin-top: 20px;
    font-size: 1em;
  }

  .content-inputs,
  .error-message,
  .content-agreement,
  .content-staticmsg,
  .header-logo,
  .content-send {
    max-width: 19em;
  }
}
